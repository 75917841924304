<template>
  <div class="login-wrapper">
    <div class="login-img" />
    <div class="box-img">
      <img src="../../assets/images/box/logo.png" alt="" />
    </div>
    <div class="login-logo">
      <img src="../../assets/images/box/logo-img.png" alt="" />
    </div>

    <div class="login-form">
      <template v-if="fromType">
        <div
          class="switch-btn cursor-p"
          @click="accountLogin('code')"
          v-if="loginType === 'newAccount' || loginType === 'forget'"
        >
          返回登录
        </div>
        <img
          class="type-switch"
          src="@/assets/images/login/type-icon-qrcode.png"
          @click="fromTypeChange(false)"
        />
        <div
          class="switch-btn cursor-p"
          @click="fromTypeChange(false)"
          v-if="loginType === 'account' || loginType === 'code'"
        >
          <span>微信扫码登录</span>
        </div>
        <!--      <div class="title">-->
        <!--        {{ $t('login.title') }}-->
        <!--        <img :src="logoUrl" />-->
        <!--      </div>-->
        <div
          class="tabTitle"
          v-if="loginType === 'account' || loginType === 'code'"
        >
          <div
            class="codeLogin"
            @click="accountLogin('code')"
            :class="{ titleActivity: loginType === 'code' }"
          >
            验证码登录
          </div>
          <div
            class="count"
            @click="accountLogin('account')"
            :class="{ titleActivity: loginType === 'account' ? true : '' }"
          >
            账号登录
          </div>
          <!--        <div class="border">-->
          <!--          <img src="../../assets/images/border.png" alt=""/>-->
          <!--        </div>-->
        </div>
        <div class="forgetBut" v-else-if="loginType === 'forget'">重设密码</div>
        <div class="forgetBut" v-else>注册账号</div>

        <!--      <transition name="fade-transform" mode="out-in">-->
        <!--    账号登录    :rules="loginRules"  -->
        <a-form
          v-if="loginType === 'account'"
          ref="loginForm"
          :model="loginForm"
          :wrapper-col="{ span: 24 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item v-bind="validateInfos.mobile">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.loginForm.mobile"
              placeholder="手机号"
              @keyup.enter="handleLogin"
            />
          </a-form-item>
          <a-form-item v-bind="validateInfos.password">
            <a-input-password
              v-model:value="form.loginForm.password"
              placeholder="密码"
              @keyup.enter="handleLogin"
              autocomplete="new-password"
            />
          </a-form-item>
          <!--          <a-form-item v-bind="validateInfos.code">-->
          <!--            <a-input-->
          <!--              autocomplete="off"-->
          <!--              v-model:value="form.loginForm.code"-->
          <!--              placeholder="验证码"-->
          <!--              @keyup.enter="handleLogin"-->
          <!--            />-->
          <!--            <div class="code-img">-->
          <!--              <a-spin :spinning="spinning" size="small">-->
          <!--                <a-image-->
          <!--                  :height="36"-->
          <!--                  :preview="false"-->
          <!--                  :src="codeSrc"-->
          <!--                  :fallback="errorImg"-->
          <!--                  @click="getCaptcha"-->
          <!--                />-->
          <!--              </a-spin>-->
          <!--            </div>-->
          <!--          </a-form-item>-->
        </a-form>

        <!--    验证码登录  -->
        <a-form
          v-else-if="loginType === 'code'"
          ref="forgetForm"
          :model="form.loginCode"
          :wrapper-col="{ span: 24 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item v-bind="validateInfosCode.validateInfos.mobile">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.loginCode.mobile"
              :placeholder="$t('login.account.placeholder')"
            />
          </a-form-item>
          <a-form-item v-bind="validateInfosCode.validateInfos.verifyCode">
            <a-input
              autocomplete="off"
              v-model:value="form.loginCode.verifyCode"
              :placeholder="$t('login.code')"
            />
            <a-button
              class="code-img"
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-form-item>
        </a-form>

        <!--    忘记密码   -->
        <a-form
          v-else-if="loginType === 'forget'"
          ref="forgetForm"
          :model="form.forgetForm"
          :wrapper-col="{ span: 24 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item v-bind="validateInfosForget.validateInfos.mobile">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.forgetForm.mobile"
              :placeholder="$t('login.account.placeholder')"
            />
          </a-form-item>
          <a-form-item v-bind="validateInfosForget.validateInfos.password">
            <a-input-password
              v-model:value="form.forgetForm.password"
              placeholder="密码"
              @keyup.enter="handleLogin"
              autocomplete="new-password"
            />
          </a-form-item>
          <a-form-item v-bind="validateInfosForget.validateInfos.verifyCode">
            <a-input
              autocomplete="off"
              v-model:value="form.forgetForm.verifyCode"
              :placeholder="$t('login.code')"
            />
            <a-button
              class="code-img"
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-form-item>
        </a-form>
        <!--    注册账号   -->
        <a-form
          v-else-if="loginType === 'newAccount'"
          ref="newAccountForm"
          :model="form.newAccount"
          :wrapper-col="{ span: 24 }"
          :validate-trigger="['change', 'blur']"
          :colon="false"
          hide-required-mark
        >
          <a-form-item v-bind="validateInfosNewAccount.validateInfos.userName">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.newAccount.userName"
              placeholder="昵称"
            />
          </a-form-item>
          <a-form-item v-bind="validateInfosNewAccount.validateInfos.mobile">
            <a-input
              allow-clear
              autocomplete="off"
              v-model:value="form.newAccount.mobile"
              :placeholder="$t('login.account.placeholder')"
            />
          </a-form-item>
          <a-form-item v-bind="validateInfosNewAccount.validateInfos.password">
            <a-input-password
              v-model:value="form.newAccount.password"
              placeholder="密码"
              @keyup.enter="handleLogin"
              autocomplete="new-password"
            />
          </a-form-item>
          <!--      邀请码   -->
          <a-form-item
            v-bind="validateInfosNewAccount.validateInfos.inviterCode"
          >
            <a-input
              :disabled="isFromInvitLink"
              allow-clear
              autocomplete="off"
              v-model:value="form.newAccount.inviterCode"
              placeholder="请输入邀请码"
            />
          </a-form-item>
          <a-form-item
            v-bind="validateInfosNewAccount.validateInfos.verifyCode"
          >
            <a-input
              autocomplete="off"
              v-model:value="form.newAccount.verifyCode"
              :placeholder="$t('login.code')"
            />
            <!--       获取验证码按钮    -->
            <a-button
              class="code-img"
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode('newAccount')"
            >
              {{ btn.title }}
            </a-button>
          </a-form-item>
        </a-form>

        <div v-else-if="['password', 'forgetPassword'].includes(show)">
          <a-alert
            show-icon
            message="请设置新密码"
            type="info"
            style="margin-bottom: 10px"
          />
          <a-form
            ref="passwordForm"
            :model="passwordForm"
            :rules="passwordRules"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 19 }"
            :validate-trigger="['change', 'blur']"
            :colon="false"
            hide-required-mark
          >
            <a-form-item v-if="show === 'password'" name="oldPassword">
              <a-input-password
                v-model:value="passwordForm.account"
                placeholder="请输入旧密码"
              />
            </a-form-item>
            <a-form-item name="password">
              <a-input-password
                v-model:value="passwordForm.password"
                placeholder="请输入8-20位新密码"
              />
              <span class="Prompt-font"
                >长度为8-20位,必须同时含有字母(大小写)、数字和符号</span
              >
            </a-form-item>
            <a-form-item name="confirmPassword">
              <a-input-password
                v-model:value="passwordForm.confirmPassword"
                placeholder="请输入确认密码"
              />
            </a-form-item>
          </a-form>
        </div>
        <!--     忘记密码  -->
        <div v-else-if="show === 'reLogin'">
          <a-alert
            show-icon
            message="修改成功，请重新登录"
            type="success"
            style="margin-bottom: 10px"
          />
        </div>
        <!--      </transition>-->
        <!--    {{ btnText[show] }}
        登录的文案，设置公共变量，这个变量根据来显示 loginType 来展示对应的文案
-->
        <!--   :class="{ butActivity: loginType === 'newAccount' }"  -->
        <div class="borderBut">
          <div
            v-if="loginType === 'account' || loginType === 'code'"
            @click="accountLogin('newAccount')"
            class="newAccount"
          >
            注册账号
          </div>
          <div
            v-if="loginType === 'account' || loginType === 'code'"
            class="forgetPassword ta-right"
            @click="accountLogin('forget')"
          >
            忘记密码
            <!--          <a class="link" @click="handleClickByLink">-->
            <!--            {{ linkText[show] }}-->
            <!--          </a>-->
          </div>
        </div>
        <div
          class="agreement"
          :class="{ agreementActivity: loginType === 'newAccount' }"
        >
          <a-checkbox v-model:checked="checked"> </a-checkbox>
<!--      todo     -->
          <div class="ml-8">
            已阅读并同意<a  @click='openUserAgreementPage'>《使用协议》</a>和
            <a  @click='openPrivacyAgreementPage'>《隐私保护协议》</a>
          </div>
        </div>
        <a-button class="btn" :loading="loading" block @click="handleLogin()">
          {{ loginText }}
        </a-button>
      </template>
      <template v-else>
        <img
          class="type-switch"
          src="@/assets/images/login/type-icon-normal.png"
          @click="fromTypeChange(true)"
        />
        <div class="switch-btn cursor-p" @click="fromTypeChange(true)">
          <span>账号登录</span>
        </div>
        <span class="form-title">微信扫码登录</span>
        <div class="code-contain">
          <div id="codeArea"></div>
          <div class="expire-mark" v-show="isExpire">
            <span class="expire-tip">二维码已过期</span>
            <div class="expire-btn" @click="getWxQrcode()">
              <span>刷新</span>
            </div>
          </div>
        </div>
        <div class="form-tips">微信扫码登录人脉宝盒</div>
        <div class='weixinZC'>
          <div class="register-link cursor-p" @click="toResigter">注册账号</div>
        </div>

      </template>
    </div>

<!--    <div class="copyright cursor-p">-->
<!--      <div @click="onloadApp" class="cursor-p downLoad">-->
<!--        <img-->
<!--          src="@/assets/images/menu/download.png"-->
<!--          style="width: 14px; margin-right: 8px"-->
<!--          alt=""-->
<!--        />-->
<!--        下载人脉宝盒APP-->
<!--      </div>-->
<!--      <div @click="gotoCopyright">-->
<!--        {{ $t('login.copyright') }}-->
<!--      </div>-->
<!--    </div>-->
    <div class="iss-logo">
      <img src="../../assets/images/login-logo.png" alt="" />
    </div>
    <!--    <div class="tips-cover">-->
    <!--      <div class="text">-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
  <privacy-agreement
    v-model:visible="privacyAgreementPage"
  />
  <terms-use
    v-model:visible="termsUsePage"
  />
</template>

<script>
import { ref, onMounted, reactive, toRefs, toRaw, watch } from 'vue';
import { useStore } from 'vuex';
// import DeviceDetector from "device-detector-js";
import { useRouter, useRoute } from 'vue-router';
import { Checkbox, Alert, Form, message } from 'ant-design-vue';
import { randoms } from '@/utils';
import { validMobile, validEmail } from '@/utils/validation';
import errorImg from '@/assets/images/error.png';
import loginApi from '@/api/login.js';
import personalApi from '@/api/personal';
import todayApi from '@/api/today';
import dashboardApi from '@/api/dashboard';
import privacyAgreement from '@/views/mRigister/components/privacyAgreement';
import termsUse from '@/views/mRigister/components/termsUse';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AAlert: Alert,
    ACheckbox: Checkbox,
    privacyAgreement,
    termsUse,
    // AImage: Image,
    // ASpin: Spin,
  },

  setup() {
    const forgetForm = ref();
    const loginForm = ref();
    const passwordForm = ref();
    const newAccountForm = ref();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const visibilityToggle = ref(true);
    const required = { required: true, whitespace: true, message: '不能为空' };
    const fromType = ref(true);
    const expireTimer = ref(null);
    const isExpire = ref(false);

    const state = reactive({
      privacyAgreementPage: false,
      termsUsePage: false,
      spinning: false,
      loading: false,
      isFromInvitLink:false,
      codeSrc: '',
      loginType: 'code',
      loginText: '登录',
      show: 'login',
      checked: false,
      // 根据字段来调取获取验证码接口
      validataCode: '',
      codeParmas: '',
      clearTimer: '',

      // 密码校验
      passwordForm: { oldPassword: '', password: '', confirmPassword: '' },
      passwordRules: {
        oldPassword: required,
        password: [
          required,
          {
            pattern:
              /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
            message: '请输入正确的密码格式',
          },
        ],
        confirmPassword: {
          validator: (rule, value) => {
            return value.trim()
              ? value === state.passwordForm.password
                ? Promise.resolve()
                : Promise.reject('两次输入密码不匹配')
              : Promise.reject('不能为空');
          },
          trigger: 'blur',
        },
      },
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      cache: {},
    });

    const form = reactive({
      // 首页登陆
      loginForm: {
        mobile: '',
        password: '',
        // key: '',
        // verifyCode: '',
        areaCode: '86',
      },
      // 忘记密码
      forgetForm: { mobile: '', verifyCode: '', password: '', areaCode: '86' },
      //  验证码登录loginCode
      loginCode: { mobile: '', verifyCode: '', areaCode: '86' },
      //  注册账号
      newAccount: {
        userName: '',
        mobile: '',
        verifyCode: '',
        password: '',
        areaCode: '86',
        inviterCode: route.query.inviterCode ? route.query.inviterCode : '',
        //  vivo、oppo、小米、华为、荣耀、魅族、ios
        channelSource: '官网',
      },
    });
    //    validate,
    // 账号登录验证
    const { validateInfos, validate, resetFields } = Form.useForm(
      form.loginForm,
      {
        password: [
          required,
          {
            pattern:
              /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
            message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
            // pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
            // message: '6-16字，同时包含数字和字母',
          },
        ],
        // key: [required],
        // verifyCode: [required],
        mobile: [
          {
            validator: async (rule, mobile) => {
              if (mobile) {
                if (validMobile(mobile) || validEmail(mobile)) {
                  return Promise.resolve();
                }
                // 或者邮箱
                return Promise.reject('请输入合法的手机号');
              }
              return Promise.reject('不能为空');
            },
          },
        ],
      }
    );

    // 验证码登录验证
    const validateInfosCode = Form.useForm(form.loginCode, {
      verifyCode: [required],
      mobile: [
        {
          validator: async (rule, mobile) => {
            if (mobile) {
              // || validEmail(mobile)
              if (validMobile(mobile)) {
                return Promise.resolve();
              }
              return Promise.reject('请输入合法的手机号');
            }
            return Promise.reject('不能为空');
          },
        },
      ],
    });
    // 忘记密码登录验证
    const validateInfosForget = Form.useForm(form.forgetForm, {
      verifyCode: [required],
      mobile: [
        {
          validator: async (rule, mobile) => {
            if (mobile) {
              //  || validEmail(mobile)
              if (validMobile(mobile)) {
                return Promise.resolve();
              }
              return Promise.reject('请输入合法的手机号');
            }
            return Promise.reject('不能为空');
          },
        },
      ],
      password: [
        required,
        {
          pattern:
            /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
          message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
        },
      ],
    });
    const validateInfosNewAccount = Form.useForm(form.newAccount, {
      verifyCode: [required],
      mobile: [
        {
          validator: async (rule, mobile) => {
            if (mobile) {
              if (validMobile(mobile) || validEmail(mobile)) {
                return Promise.resolve();
              }
              // 或者邮箱
              return Promise.reject('请输入合法的手机号');
            }
            return Promise.reject('不能为空');
          },
        },
      ],
      password: [
        required,
        {
          pattern:
            /^(?![A-z0-9]+$)(?=.[^%&',;=?$\x22])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,20}$/,
          message: '长度为8-20位，必须同时含有字母(大小写)、数字和符号',
        },
      ],
      userName: [required],
      // inviterCode:
    });

    const toResigter = () => {
      fromType.value = true;
      state.loginType = 'newAccount';
    };

    const fromTypeChange = type => {
      console.log('type', type)
      if (type) {
        fromType.value = true;
      } else {
        fromType.value = false;
        getWxQrcode();
      }
    };

    const getWxQrcode = () => {
      clearTimeout(expireTimer.value);
      isExpire.value = false;
      localStorage.setItem('ctime', new Date().getTime());
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js';
      const wxElement = document.body.appendChild(s);
      wxElement.onload = function () {
        // eslint-disable-next-line no-undef
        var obj = new WxLogin({
          id: 'codeArea', // 需要显示的容器id
          appid: 'wx1a4ffc2f87b353f5', // 公众号appid wx*******
          scope: 'snsapi_login', // 网页默认即可
          redirect_uri: encodeURIComponent(
            process.env.VUE_APP_QRCODE_ADDRESS + '/#/getCode'
          ), // 授权成功后回调的url
          state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
          style: 'white', // 提供"black"、"white"可选。二维码的样式
          href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDE2MHB4O21hcmdpbi10b3A6MDt9Ci5pbXBvd2VyQm94IHt3aWR0aDoxNjBweDtsaW5lLWhlaWdodDowO30KLmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAxNjBweDt9Ci5zdGF0dXNfaWNvbiB7ZGlzcGxheTogbm9uZX0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30gCi53YWl0aW5ne3Bvc2l0aW9uOnJlYWx0aXZlfQouc3RhdHVzX3N1Y2N7d2lkdGg6MTAwJTtoZWlnaHQ6MTAwJTtwYWRkaW5nOjAgIWltcG9ydGFudDtkaXNwbGF5OmZsZXg7YWxpZ24taXRlbXM6Y2VudGVyO2p1c3RpZnktY29udGVudDogY2VudGVyO3Bvc2l0aW9uOmFic29sdXRlO3RvcDo1MCU7bGVmdDo1MCU7dHJhbnNmb3JtOnRyYW5zbGF0ZSgtNTAlLC01MCUpO2JhY2tncm91bmQ6IHJnYmEoMCwgMCwgMCwgMC42KTt9Ci5pbXBvd2VyQm94IC5zdGF0dXNfdHh0e2Rpc3BsYXk6bm9uZX0=', // 外部css文件url，需要https
        });
      };
      expireTimer.value = setTimeout(() => {
        isExpire.value = true;
      }, 120000);
    };

    const getBehaviorPage = () => {
      //  获取用户导入行为页面信息
      dashboardApi
        .getBehaviorPage({
          userId: store.state.account.user.id,
          importSituation: 0,
        })
        .then(res => {
          // 有数据就去我设置的页面，否去dashboard页面
          if (res) {
            // console.log('shuju shuju', res, typeof res);
            // console.log('13', res);
            router.push({
              path: res,
              query: {
                importStatus: 0,
              },
            });
          } else {
            // console.log('14', res);
            router.push({
              path: '/dashboard',
              query: {
                importStatus: 0,
              },
            });
          }
        });
    };

    const getCaptcha = () => {
      state.spinning = true;
      form.loginForm.key = randoms(24, 16);
      loginApi
        .getCaptcha(form.loginForm.key)
        .then(data => (state.codeSrc = data))
        .catch(err => {
          console.log(err);
          getCaptcha();
        })
        .finally(() => (state.spinning = false));
    };
    const getImportSituation = () => {
      // console.log('id', store.state.account.user.id);
      todayApi
        .getContactImportSituation('', {
          userId: store.state.account.user.id,
        })
        .then(res => {
          if (res) {
            // "Novice"新手 "incomplete"未完成    "untreated" 未处理  “complete”完成
            if (res === 'untreated') {
              router.push('/today');
            } else if (res === 'Novice') {
              router.push('/welcome');
            } else if (res === 'incomplete') {
              // debugger;
              getBehaviorPage();
            } else if (res === 'complete') {
              router.push('/today');
            } else {
              debugger;
            }
          }
        });
    };

    onMounted(() => {
      if(route.query?.inviterCode){
        state.loginType = 'newAccount'
        state.isFromInvitLink=true
      }
      // const deviceDetector = new DeviceDetector();
      // const userAgent = window.navigator.userAgent;
      // let isDeveice = deviceDetector.parse(userAgent);
      // console.log('device', isDeveice)
      // alert(isDeveice)
      // if (isDeveice.device.model === 'iPhone') {
      //   form.newAccount.channelSource = 'ios'
      // }else if (isDeveice.device.model === 'oppo'){
      //   form.newAccount.channelSource = 'oppo'
      // }else if (isDeveice.device.model === '小米'){
      //   form.newAccount.channelSource = '小米'
      // }else if (isDeveice.device.model === '华为'){
      //   form.newAccount.channelSource = '华为'
      // }else if (isDeveice.device.model === '荣耀'){
      //   form.newAccount.channelSource = '荣耀'
      // }else if (isDeveice.device.model === '魅族'){
      //   form.newAccount.channelSource = '魅族'
      // }
      // getCaptcha()
    });

    // watch(
    //   () => state.clearTimer,
    //   value => {
    //     console.log('watch', value);
    //     // clearInterval(state.clearTimer);
    //   },
    //   { deep: true }
    // );

    // created() {
    //   this.getCaptcha();
    // },

    return {
      // validAccount,
      ...toRefs(state),
      getImportSituation,
      form,
      fromType,
      getCaptcha,
      visibilityToggle,
      forgetForm,
      loginForm,
      passwordForm,
      newAccountForm,
      required,
      resetFields,
      validateInfos,
      validateInfosCode,
      validateInfosForget,
      validateInfosNewAccount,
      toResigter,
      fromTypeChange,
      getWxQrcode,
      expireTimer,
      isExpire,
      logoUrl: process.env.VUE_APP_PROJECT_LOGIN_LOGO,
      errorImg,
      type: {
        loginType: '',
      },
      btnText: {
        login: '立即登录',
        forget: '下一步',
        forgetPassword: '完成',
        password: '完成',
        reLogin: '重新登录',
      },
      linkText: {
        login: '忘记密码',
        forget: '登录',
        forgetPassword: '登录',
        signAccount: '注册账号',
      },
      // 忘记密码按钮
      handleClickByLink() {
        // this.show==='forgetPassword' && this.$refs.passwordForm.clearValidate()
        state.show = state.show === 'login' ? 'forget' : 'login';
      },
      accountLogin(value) {
        state.loginType = value;

        state.btn = { disabled: false, title: '获取验证码' };
        clearInterval(state.clearTimer);
        state.clearTimer = null;
        if (value === 'newAccount') {
          state.loginText = '注册并登录';
        } else {
          state.loginText = '登录';
        }
      },
      gotoCopyright() {
        window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
      },
      onloadApp() {
        window.open(
          'https://ismartek-app-uat.issmart.com.cn/apk/ctb/test/v1.2.0-test.apk',
          '_self'
        );
      },
      // 登录方法
      handleLogin() {
        if (state.loginType === 'account') {
          validate().then(() => {
            if (!state.checked) {
              return message.error('请勾选协议！');
            }
            state.loading = true;
            loginApi
              .loginByAccount('', toRaw(form.loginForm))
              .then(res => {
                if (res) {
                  store.commit('account/setToken', res.token);
                  store.commit('account/setUser', res);
                  // message.success('登录成功');
                  // router.push('/');
                  getImportSituation();
                  state.checked = false;
                  resetFields();
                }
              })
              .catch(() => {
                form.loginForm.verifyCode = '';
              })
              .finally(() => {
                setTimeout(() => {
                  state.loading = false;
                }, 1500);
              });
          });
        }

        if (state.loginType === 'code') {
          validateInfosCode.validate().then(() => {
            if (!state.checked) {
              return message.error('请勾选协议！');
            }

            state.loading = true;
            loginApi
              .loginByVerifyCode('', toRaw(form.loginCode))
              .then(res => {
                if (res) {
                  // message.success('登录成功');
                  store.commit('account/setToken', res.token);
                  store.commit('account/setUser', res);
                  getImportSituation();
                  state.checked = false;
                  // router.push('/');
                  validateInfosCode.resetFields();
                }
                // else {
                //   message.error('验证码错误,请重新输入验证码');
                // }
              })
              .catch((e) => {
                form.loginForm.verifyCode = '';
              })
              .finally(() => {
                setTimeout(() => {
                  state.loading = false;
                }, 1500);
              });
          });
        }

        if (state.loginType === 'forget') {
          validateInfosForget.validate().then(() => {
            if (!state.checked) {
              return message.error('请勾选协议！');
            }

            state.loading = true;
            loginApi
              .resetPassword('', toRaw(form.forgetForm))
              .then(res => {
                if (res) {
                  // message.success('登录成功');
                  store.commit('account/setToken', res.token);
                  store.commit('account/setUser', res);
                  state.checked = false;
                  // router.push('/');
                  getImportSituation();
                  validateInfosForget.resetFields();
                }
              })
              .catch(() => {
                form.loginForm.verifyCode = '';
              })
              .finally(() => {
                setTimeout(() => {
                  state.loading = false;
                }, 1500);
              });
          });
        }
        // 注册并登录
        if (state.loginType === 'newAccount') {
          validateInfosNewAccount.validate().then(() => {
            if (!state.checked) {
              return message.error('请勾选协议！');
            }
            state.loading = true;

            loginApi
              .addUserAndLoginAndSendGold('', toRaw(form.newAccount))
              .then(res => {
                if (res) {
                  // message.success('注册并登录成功');
                  validateInfosNewAccount.resetFields();
                  store.commit('account/setToken', res.token);
                  store.commit('account/setUser', res);
                  state.loginType = 'code';
                  state.loginText = '登录';
                  getImportSituation();
                  state.checked = false;
                  clearInterval(state.clearTimer);
                  state.clearTimer = null;
                  // router.push('/');
                }

              })
              .catch(() => {
                form.loginForm.verifyCode = '';
                // getCaptcha();
              })
              .finally(() => {
                setTimeout(() => {
                  state.loading = false;
                }, 1500);
              });
          });
        }
      },

      // 验证码处理 todo
      handleGetCode() {
        if (state.loginType === 'newAccount') {
          state.validataCode = validateInfosNewAccount;
          state.codeParmas = form.newAccount.mobile;
        } else if (state.loginType === 'code') {
          state.validataCode = validateInfosCode;
          state.codeParmas = form.loginCode.mobile;
        } else if (state.loginType === 'forget') {
          state.validataCode = validateInfosForget;
          state.codeParmas = form.forgetForm.mobile;
        }

        state.validataCode?.validate('mobile').then(() => {
          loginApi.getCode(state.codeParmas).then(res => {
            message.success('验证码已发送，请注意查收');
            if (res) {
              // store.commit('account/setTenant', data);
              state.btn = { disabled: true, title: '重新获取(60s)' };
              let count = 60;
              state.clearTimer = setInterval(() => {
                if (count === 1) {
                  state.btn = { disabled: false, title: '获取验证码' };
                  clearInterval(state.clearTimer);
                  state.clearTimer = null;
                } else {
                  count--;
                  state.btn.title = `重新获取(${count}s)`;
                }
              }, 1000);
            }

            // else {
            //   message.error('该账号不存在企业信息，请重新输入');
            // }
          });
        });
      },

      // 设置完新密码的按钮
      handlePassword() {
        let temp = ['password', 'confirmPassword'];
        if (state.show === 'password') {
          temp.push('oldPassword');
        }
        passwordForm.value.validateFields(temp).then(() => {
          state.loading = true;
          personalApi
            .updatePassword({
              ...state.passwordForm,
              id: state.cache.user.userId,
            })
            .then(res => {
              if (res) {
                localStorage.clear();
                // this.handleLoginCount();
                state.show = 'reLogin';
              }
            })
            .finally(() => (state.loading = false));
        });
      },
      // 隐私保护协议
      openPrivacyAgreementPage() {
        router.push({
          path: '/agreement',
        });

        // state.privacyAgreementPage = true
      },
      // 使用协议
      openUserAgreementPage() {
        router.push({
          path: '/agreement/userAgreement',
        });

      },

    };
  },
};
</script>

<style lang="less" scoped>
.login-wrapper {
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  //.login-logo {
  //  flex: 1;
  //  background: linear-gradient(90deg, #7e98ff 0%, #5979f8 100%);
  //  position: relative;
  //}
  .copyright {
    color: #1d2129;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: 20px;
  }
  .iss-logo {
    width: 12%;
    position: absolute;
    left: 5%;
    top: 5%;
    img {
      width: 100%;
    }
  }
  .box-img {
    position: absolute;
    top: 52px;
    left: 52px;
  }
  .login-img {
    min-width: 960px;
    flex: 3;
    background: url('../../assets/images/box/back-img.png') no-repeat center;
    background-size: 100% 100%;
    background-position: right;
  }
  .login-form {
    min-width: 320px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12%;
    max-width: 80%;
    width: 380px;
    //height: 500px;
    height: 560px;
    padding: 54px 40px 40px;
    background-color: #ffffffcc;
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    :deep(.ant-form label) {
      font-size: 16px;
    }

    .type-switch {
      width: 64px;
      height: 64px;
      object-fit: cover;
      position: absolute;
      top: -2px;
      right: -2px;
      cursor: pointer;
    }

    .switch-btn {
      padding: 5px 12px;
      box-sizing: border-box;
      background: #ffece5;
      border-radius: 5px;
      position: absolute;
      top: 20px;
      right: 70px;
      color: #ff7b00;

      span {
        color: #ff7b00;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .form-title {
      color: #333333;
      font-size: 24px;
      font-weight: 500;
    }

    .code-contain {
      width: 192px;
      height: 192px;
      margin: 66px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      background: url('../../assets/images/login/code-contain-back.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;

      .expire-mark {
        width: 180px;
        height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        gap: 22px;

        .expire-tip {
          color: #ffffff;
          font-size: 18px;
          font-weight: 400;
        }

        .expire-btn {
          padding: 8px 32px;
          box-sizing: border-box;
          border: 1px solid #ffffff;
          border-radius: 5px;
          cursor: pointer;

          span {
            color: #ffffff;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      #codeArea {
        width: 160px;
        height: 160px;
      }
    }

    .form-tips {
      width: 100%;
      margin-top: 54px;
      //display: inline-block;
      color: #999999;
      font-size: 16px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .weixinZC {


    }

    .register-link {
      //width: 100%;
      margin-top: 28px;
      color: #ff7b00;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      position: absolute;
      top: 76%;
      left: 42%;
    }
    //.title {
    //  font-weight: bold;
    //  margin-bottom: 2vw;
    //  font-size: 2rem;
    //  img {
    //    width: 60%;
    //    height: auto;
    //  }
    //}
    :deep(.ant-form-item) {
      //margin-bottom: 16px;
      .anticon-eye,
      .anticon-eye-invisible {
        color: rgba(0, 0, 0, 0.25);
      }
      &.ant-form-item-with-help {
        margin-bottom: 0;
      }
    }
    :deep(.ant-form-item-label) {
      text-align-last: justify;
      text-align: justify;
      text-justify: distribute-all-lines; // 兼容 IE
    }
    :deep(.ant-input),
    :deep(.ant-input-affix-wrapper),
    :deep(.ant-input-affix-wrapper-focused) {
      line-height: 30px;
      border-radius: 4px;
      //border-width: 0px 0px 1px 0px !important;
      box-shadow: none;
      background-color: #fff;
      &:hover,
      &:focus {
        //border-right-width: 0px !important;
        box-shadow: none;
      }
    }
    .code-img {
      position: absolute;
      right: 0;
      top: 4px;
      right: 4px;
      cursor: pointer;
      :deep(.ant-image-img) {
        width: auto;
        height: 100%;
      }
    }
    .btn {
      margin: 20px 0 10px 0;
      height: 40px;
      font-size: 16px;
      position: absolute;
      top: 470px;
      width: 304px;
      background: linear-gradient(0deg, #ff7b00 0%, #ffaa5b 100%);
      color: #ffffff;
    }
    .link {
      font-size: 14px;
      color: #b0bec5;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        color: @primary-color;
      }
    }
    :deep(.ant-input-clear-icon) {
      margin: 0;
    }
  }
}
.Prompt-font {
  //font-family: 'Arial Normal', 'Arial';
  font-weight: 400;
  font-style: normal;
  font-size: 10px;
  letter-spacing: normal;

  color: rgba(49, 61, 95, 0.6);
  font-family: PingFangSC-Semibold;
}
.tabTitle {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
.forgetBut {
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
.codeLogin {
  margin-right: 40px;
}
.borderBut {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  //margin-top: 24px;
  padding-top: 24px;
}
.goBack {
  background: #ffece5;
  border-radius: 5px;
  color: #ff7b00;
  text-align: center;
  width: 94px;
  position: absolute;
  top: 19px;
  right: 19px;
  cursor: pointer;
}
.agreement {
  color: #999999;
  margin-top: 24px;
  font-size: 12px;
  position: absolute;
  top: 340px;
  width: 304px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.agreementActivity {
  margin-top: 0px;
  //top: 372px;
  top: 430px;
}
.butActivity {
  margin-top: 0px;
}
.titleActivity {
  background-image: url(../../assets/images/border.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 0px 35px;
}
.login-logo {
  position: absolute;
  top: 229px;
  right: 678px;
}

@media screen and (min-width: 1366px) and (max-width: 1920px) {
  .login-logo {
    position: absolute;
    top: 229px;
    right: 778px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .login-logo {
    position: absolute;
    top: 229px;
    right: 678px;
  }
}
.newAccount {
  color: #ff7b00;
}
.forgetPassword {
  color: #fa5151;
}
.downLoad {
  text-align: center;
  color: #ff7b00;
  border: 1px solid #ff8f1f;
  border-radius: 8px;
  padding: 10px 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 166px;
  //margin-left: 16px;
  margin-bottom: 20px;
  margin-left: 32%;
}
</style>
